/* style.css */

.image-carousel-container {
  width: 900px;
  max-width: 100%;
  margin: 0 auto;
}

.slick-slide img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

@media (max-width: 768px) {

  .image-carousel-container {
    width: 100%;
  }
}