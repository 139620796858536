.reviews-management-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 800px;
    width: 100%;

    .review-management-status-section {
        border: 2px solid rgb(0, 43, 54);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        .no-review-data {
            display: flex;
            min-height: 100px;
            align-items: center;
            justify-content: center;
        }


        .review-data {
            border: 2px solid rgb(0, 43, 54);
            gap: 10px;
            border-radius: 5px;
            padding: 10px;
            .title2 {
                text-align: left;
            }
            p {
                margin: 0;
            }
            .custom-button {
                padding: 6px 12px !important;
                color: #000 !important;
            }
        }
    }

    .accept {
        background: #00d100 !important;
    }

    .reject {
        background: #da0100 !important;
    }

    .delete {
        background: #c8c8c8 !important;
    }

    .disabled {
        background: #c8c8c861 !important;
        cursor: wait !important;
    }

}