.slider-item {
    position: relative;
}

.slider-image {
    width: 100%;
    height: auto;
    max-height: 600px;
    object-fit: contain; /* Maintain aspect ratio */
}
  

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}