/* YourStyles.css */

.fixed-background {
  padding: 0;
  font-family: "Your Desired Font", sans-serif;
  font-size: 20px;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  top: 0;
  position: fixed;
}
.home-container {
  z-index: 2;
  position: relative;

  .service_description {
    text-align: justify;
  }

  .transparent-section {
    background: rgba(0, 0, 0, 0.50);
    .fade-centered {
      .full-column {
        .common_text, .title {
          color: #ffffff !important;
        }
      }
    }
  }


  .right-padding {
    padding: 10px 0 !important;
  }

  .vertical-padding {
    padding-bottom: 20px;
    padding-top: 30px;
  }

  .darker-background {
    background-color: #E0EFD8;
  }

  .space-between {
    padding: 25px 0;
  }

  .two-column {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-family: "Your Desired Font", sans-serif;
    /* Change the font family */
    font-size: 20px;
    /* Change the font size (adjust the value as needed) */
  }

  .full-column {
    flex: 1;
    padding: 50px 60px;
    max-width: 60%;
    margin: 0 2px;
  }

  .left-column,
  .right-column {
    flex: 1;
    padding: 0 60px;
    max-width: 30%;
    margin: 0 2px;

  }


  /* Style for h2 elements */
  h2 {
    color: #D0FE1D;
    font-family: "Your Desired Font", sans-serif;
  }

  .service_description {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  p,
  ol,
  label {
    color: #666666 !important;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Adjust the opacity (4th value) to darken or lighten the image */
  }

}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .home-container {
    .full-column {
      padding: 40px 0px 20px 20px;
    }

    .background-image {
      background-size: contain;
      padding: 70px;
      background-size: cover;
    }

    .center {
      max-width: 100%;
      margin: 20px 0;
      font-size: 16px;
      /* Adjust the font size for smaller screens */
      padding: 0 20px;
      text-align: justify;
    }

    .space-between {
      padding: 20px 0;
    }

    .two-column {
      flex-direction: column;
      font-size: 16px;

      h2 {
        margin-top: 20px;
      }
    }

    .left-column,
    .right-column {
      max-width: 100%;
      margin: 10px 0;
      padding: 0 20px;
    }
  }
}