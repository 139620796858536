body {
  margin: 0;
  font-family: "Your Desired Font", sans-serif !important;
  overflow-x: hidden;
  background-color: #E0EFD8;

  /* Style for h2 elements */
  .title {
    color: #D0FE1D;
    font-family: "Your Desired Font", sans-serif;
    font-weight: bold
  }
  .title2 {
    color: #002B36;
    font-family: "Your Desired Font", sans-serif;
    font-weight: bold;
    text-align: center;
  }

  .common_text {
    font-family: "Your Desired Font", sans-serif;
    color: #4d4c4c;
  }

}

html body p h {
  font-family: "Your Desired Font", sans-serif !important;
}

#root {
  overflow-x: hidden;

  .custom-button {
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-weight: 600;
    border-radius: 3px;
    padding: 12px 24px;
    border: 0;
    color: #D0FE1D;
    background: #002B36;
    line-height: 1.15;
    font-size: 16px;
    :hover {
        transition: all .1s ease;
        box-shadow: 0 0 0 0 #fff, 0 0 0 3px #1de9b6;
    }
    &:disabled {
      color: #002B36;
      background: #d3d3d3a9;
    }
  
  }

  .cancel-button {
    color: #002B36;
    background: #d3d3d3a9;
  }

  
}

