.gallery-container {
  text-align: center justify;
  padding: 0px;
  position: relative;

  /* Position relative for h1 and image positioning */

  .img-grid {
    margin: 20px auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
    max-width: 100%;
  }

  .img-wrap {
    overflow: hidden;
    height: 0;
    padding: 50% 0;
    position: relative;
    opacity: 0.8;
  }

  .img-wrap:hover {
    cursor: pointer;
    opacity: 0.7;
    -webkit-transition: opacity 0.5s ease-out;
    -moz-transition: opacity 0.5s ease-out;
    -o-transition: opacity 0.5s ease-out;
    transition: opacity 0.5s ease-out;
  }

  .img-wrap img {
    min-width: 100%;
    min-height: 100%;
    max-width: 150%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .full-column {

    width: 70%;
    /* Set the desired width */
    margin: 0 auto;
    /* Center the column */
  }

  .background-image {
    padding: 200px;
    max-height: 30px;
    /* Set the maximum height to 30px */
    width: auto;
    position: relative;
    opacity: 1;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;

  }


  .white-text {
    color: rgb(255, 255, 255);
    position: absolute;
    top: 20%;
    /* Adjust the value to position it slightly higher */
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-size: 6rem;
    /* Adjust the font size as needed */
    font-weight: bold
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    /* Adjust the opacity (4th value) to darken or lighten the image */
  }

  .movements-container {
    display: flex;
    width: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 5px;
    margin-top: 20px;

    .movement, .selected-service{
      font-size: 2rem;
      background-color: #002B36 !important;
      /* Change the background color of the active tab */
      color: #D0FE1D;
      border-radius: 8px;
      padding: 5px;
    }
    .selected-service{
      margin-bottom: 0;
      padding-left: 10px;
      padding-right: 10px;
    }

    .movement {
      width: 50px;
    }

    .movement:hover {
      /* Change the text color on hover */
      opacity: 0.7;
      -webkit-transition: opacity 0.5s ease-out;
      -moz-transition: opacity 0.5s ease-out;
      -o-transition: opacity 0.5s ease-out;
      transition: opacity 0.5s ease-out;
    }
  }


  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .background-image {
      background-size: contain;
      padding: 70px;
      background-size: cover;
    }

    .white-text {
      font-size: 2rem;
      top: 30%;
      /* Adjust the value for vertical positioning */
    }

    .img-grid {
      grid-template-columns: 1fr;
      /* Display one column on smaller screens */
    }

    .full-column {
      width: 90%;
      /* Adjusted width for smaller screens */
    }

    .nav-pills .nav-link {
      font-size: 1rem;
    }
  }
}