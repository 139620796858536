.fade-centered {
    display: flex;
    width: 100%;
    justify-content: center;
}

.fade-in-section {
    opacity: 0;
    visibility: hidden;
    will-change: opacity, visibility;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
}

.y-fade {
    transform: translateY(20vh);
}

.x-fade {
    transform: translateX(-40vh);
}

.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}