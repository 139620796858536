.pages-content-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 800px;
    width: 100%;

    .pages-content-section {
        border: 2px solid rgb(0, 43, 54);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;

        .custom-button {
            padding: 6px 12px !important;
            text-wrap: nowrap;
        }

        .content-data {
            border: 2px solid rgb(0, 43, 54);
            gap: 10px;
            border-radius: 5px;
            padding: 10px;

            .option-to-edit {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                vertical-align: middle;
                gap: 10px;
                padding: 5px 0;

                ol,
                li,
                input[type="text"] {
                    max-width: 500px;
                    width: 100%;
                }
            }

            .title2 {
                text-align: left;
                font-size: 16px;
                margin: 0;
            }

            p {
                margin: 0;
            }

            .gen-opt {
                max-width: 190px;
                width: 100%
            }

            .add-image-panel {
                .option-to-edit {
                    flex-direction: row;

                    ol,
                    li,
                    input[type="text"] {
                        max-width: none;
                    }
                }

                .gen-opt {
                    max-width: none;
                }
            }
        }
    }
}

@media (max-width: 430px) {
    .pages-content-container {
        .pages-content-section {
            .custom-button {
                text-wrap: wrap;
            }
        }
    }
}