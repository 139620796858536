
.icon-container {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    
    & :hover {
      opacity: 0.7;
      -webkit-transition: opacity 0.5s ease-out;
      -moz-transition: opacity 0.5s ease-out;
      -o-transition: opacity 0.5s ease-out;
      transition: opacity 0.5s ease-out;
    }

    & a {
        text-decoration: none;
    }
  }