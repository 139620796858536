
.star-rating {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 10px 10px 10px;
  }
  
  .stars {
    display: flex;
  }
  
  .star {
    font-size: 44px;
    color: #ddd;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .star.filled {
    color: #ffc107; /* Change this color to the desired filled star color */
  }
  
  .progress-bar {
    height: 10px;
    width: 100%;
    background-color: #ffc107;
    margin-top: 10px;
  }
  
  .progress-bar::before {
    content: '';
    height: 100%;
    width: 0;
    background-color: #ffc107; /* Change this color to the desired filled progress bar color */
    transition: width 0.3s;
  }
  

  .star-description {
    text-align: center;
    font-style: italic;
  }

  .__card {
    border: 1px solid #002B36;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;

  }