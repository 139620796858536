.ratings-card {
    background-color: #E0EFD8;
    padding-bottom: 10px;

    .slick-slider {
        position: relative;
    }
    .custom-arrow {
        z-index: 1;
        position: absolute;
        width: 50px;
        height: 50px;
        cursor: pointer;
        top: 85%;
    }

    .custom-next {
        right: 25px;
    }

    .custom-prev {
        left: 25px;
    }
    
    .add-review-container {
        padding: 20px;
        background-color: #E0EFD8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 100vh;
        max-height: 100vh;
        margin: 60px auto;
        box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
        border-radius: 8px;

        .__card {
            border: 0;
            padding: 0;
            margin: 0;
        }
    }
}