/* Navbar.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #002B36;
  ;
  padding: 20px 10px;
  z-index: 3;
}

.logo img {
  cursor: pointer;
}


.logo a:hover {
  opacity: 0.7;
  -webkit-transition: opacity 0.5s ease-out;
  -moz-transition: opacity 0.5s ease-out;
  -o-transition: opacity 0.5s ease-out;
  transition: opacity 0.5s ease-out;
}

.nav-options {
  display: flex;
  align-items: center;
}

.nav-options a {
  color: #D0FE1D;
  text-decoration: none;
  font-size: 1.75rem;
  margin-right: 20px;
  cursor: pointer;
}

.nav-options a:hover {
  opacity: 0.7;
  -webkit-transition: opacity 0.5s ease-out;
  -moz-transition: opacity 0.5s ease-out;
  -o-transition: opacity 0.5s ease-out;
  transition: opacity 0.5s ease-out;
}

.hamburger {
  display: none;
  cursor: pointer;
  .bar {
    background-color: #D0FE1D;
  }
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #000;
  margin: 5px 0;
}

.img-logo {
  width: 300px;
  height: auto;
  object-fit: cover;
}

@media (max-width: 768px) {

  .img-logo {
    width: 250px;
    height: auto;
    object-fit: cover;
  }

  .nav-options {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: 0;
    background-color: #FFFF;
    width: 100%;
    text-align: center;
  }

  .nav-options.open {
    z-index: 2;
    display: flex;
    background-color: #002b36;
  } 

  .hamburger {
    display: flex;
    flex-direction: column;
  }
}