/* YourStyles.css */
.about-us-container {
    
    text-align: center justify;
    padding: 0px;
    position: relative; /* Position relative for h1 and image positioning */
  
    .center {
      padding: 0 100px;
      text-align: center;
      max-width: calc(70% - 100px); /* Adjust the value to make it as wide as both columns together */
      margin: 40px auto; /* Center the content horizontally */
      font-family: "Your Desired Font", sans-serif; /* Change the font family */
      font-size: 20px; /* Change the font size (adjust the value as needed) */
  
    }
    
    .two-column {
      
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      font-family: "Your Desired Font", sans-serif; /* Change the font family */
      font-size: 16px !important; /* Change the font size (adjust the value as needed) */
  
      
      
    }
    
    .left-column,
    .right-column {
      flex: 1;
      padding: 0 60px;
      max-width: 35%;
      margin: 0 2px;
  
    }
    
    .bottom {
      margin-top: 20px;
    }
    
    
    /* Style for the background image */
    .background-image {
      padding: 200px;
      max-height: 30px; /* Set the maximum height to 30px */
      width: auto;
      position: relative;
      opacity: 1;
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      
    }
    .white-text {
      color: rgb(255, 255, 255);
      position: absolute;
      top: 20%; /* Adjust the value to position it slightly higher */
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      font-size: 6rem; /* Adjust the font size as needed */
      font-weight: bold
    }
    p,ol,label{
      color: #666666 !important;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6); /* Adjust the opacity (4th value) to darken or lighten the image */
    } 
}

@media (max-width: 768px) {
      .about-us-container { 
      .background-image {
        background-size: contain;
        padding: 70px;
        background-size: cover;
      }
    
      .white-text {
        font-size: 2rem;
        top: 30%; /* Adjust the value for vertical positioning */
      }
    
      .center {
        max-width: 100%;
        margin: 20px 0;
        font-size: 16px; /* Adjust the font size for smaller screens */
        padding: 0 20px;
        text-align: justify;
      }
    
      .two-column {
        flex-direction: column;
        font-size: 16px;
        h2 {
          margin-top: 20px;
        }
      }
    
      .left-column,
      .right-column {
        max-width: 100%;
        margin: 10px 0;
        padding: 0 20px;
      }
    }
}
  