.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: #E0EFD8;
  z-index: 1;
  & p{
    margin: 0;
  }
  .container-fluid {
    padding: 0;
  }

  .ending {
    max-width: 600px;
    text-align: center;
    font-size: 14px;
  }
  .background-footer {
    background: linear-gradient(0deg, rgb(15, 8, 8),#002B36);
    width:  100%;
    color: white;
    padding-bottom: 30px;
  }

.singleCol{
  padding-top: 10px;
    max-width: 300px;
    margin: 0 auto;
    top:40%;
    display: flex; /* Add display: flex */
    
}
.move{
    top:50%;
}
.social-media-icons-white a{
   
    color: white;
    font-size: 2rem;
}
.Columns {
    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-family: "Your Desired Font", sans-serif; /* Change the font family */
    margin-top: 40px; 
    
  }
  
  .columnLeft, .columnRight {
    flex: 1;
    padding: 0 50px;
    max-width: 35%;
    margin: 0 2px;
    
  }
  .footText{
    color: white; 
  }
}

@media (min-width: 769px) {
  .columnRight {    
    display: flex;
    flex-direction: column;
    align-items: end;
  }
}

@media (max-width: 768px) {
  .footer {
    .Columns {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      gap: 20px;
      font-size: 16px;
      h3 {
        margin-top: 20px;
      }
    }
    .background-footer {
      padding-bottom: 50px;
    }
    .social-media-icons-white a {
      margin-bottom: 30px; /* Add spacing between social media icons */
    }

    /* Adjust the alignment of the columns */
    .columnLeft,
    .columnRight {
        max-width: 100%;
        padding: 0 15px; /* Add padding to maintain spacing */
    }
  }
}