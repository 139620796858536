.admin-container {
    .no-selected-option {
        background: #ffffffcc !important;
        color: #000 !important;
    }
    .admin-tab-content {
        width: 100%;
        display: flex;
        padding: 20px;
        justify-content: center;
    }
}