.contact-container {
    text-align: center justify;
    position: relative;
    padding: 0px;

    & p, button, input, optgroup, select, textarea{
      font-size: 16px;
    }
    .twoColumn {
      
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      font-family: "Your Desired Font", sans-serif; /* Change the font family */
      font-size: 20px; /* Change the font size (adjust the value as needed) */
      margin-top: 40px; 
    }
    .imput_area{
      background-color: #E0EFD8;
    }
    
    .leftColumn,
    .rightColumn {
      flex: 1;
      padding: 0 50px;
      max-width: 35%;
      margin: 0 2px;
    }
    .backgroundImage {
      padding: 200px;
      max-height: 30px; /* Set the maximum height to 30px */
      width:auto;
      position: relative;
      opacity: 1;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      
    }
    .whiteText {
      color: rgb(255, 255, 255);
      position: absolute;
      top: 20%; /* Adjust the value to position it slightly higher */
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      font-size: 6rem; /* Adjust the font size as needed */
      font-weight: bold;
    }
    .vertical-form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px; /* Optional spacing between form elements */
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6); /* Adjust the opacity (4th value) to darken or lighten the image */
    }
    .map-container {
      position: relative;
      padding-bottom: 75%; /* Controls the aspect ratio for desktop */
      
    }
  }


  @media (max-width: 1110px) {
    .contact-container {
      .backgroundImage {
        background-size: cover;
        padding: 70px;
        
      }
    
      .whiteText {
        font-size: 2rem;
        top: 30%; /* Adjust the value for vertical positioning */
      }
    
      
    
      .twoColumn {
        flex-direction: column;
        font-size: 16px;
        h2 {
          margin-top: 20px;
        }
      }
    
      .leftColumn,
      .rightColumn {
        max-width: 100%;
        margin: 10px 0;
        padding: 0 20px;
      }
      .map-container {
        height: 150px; /* Set the desired height for the map on mobile screens */
        width: 70%; /* Set the desired width for the map on mobile screens */
        max-width: 100%; /* Prevent the container from exceeding the screen width */
        margin: 0 auto; /* Center the map horizontally */
        overflow-x: hidden; /* Hide horizontal overflow, preventing the map from making the window wider */
      }
      .iframe {
        width: 300px; 
      }
      .vertical-form textarea {
        width: 100%; /* Adjust the width as needed */
        max-width: 100%; /* Adjust the maximum width as needed */
      }
      #logo {
        width: 340px !important;
        object-fit: cover !important;
      }
    }  
  }